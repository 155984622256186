/* eslint-disable */
import { useMutation } from "@apollo/client";
import { Box } from "@material-ui/core";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { INIT_PROVIDER } from "../graphql/mutations/provider";
import gtm from "../lib/gtm";

const SquidGamePage = () => {
  const [loadProvider, { data }] = useMutation(INIT_PROVIDER, {
    variables: {
      model: {
        providerId: 3,
        gameId: 1,
      },
    },
  });

  useEffect(() => {
    gtm.push({ event: "page_view" });
    loadProvider();
  }, []);

  const redirectUrl = encodeURIComponent("https://mtcoinplay.com/");

  const iframeFullUrl = data?.initProvider?.iframeUrl.concat(
    `&dark=1&nature=1&client=squid-game&redirectUrl=${redirectUrl}`
  );

  return (
    <>
      <Helmet>
        <title>MTCoins: Squid Game</title>
      </Helmet>
      {iframeFullUrl && (
        <Box
          as="iframe"
          src={iframeFullUrl}
          sx={{
            position: "fixed",
            top: 0,
            let: 0,
            width: "100%",
            height: "100%",
            border: "none",
          }}
        />
      )}
    </>
  );
};

export default SquidGamePage;
