/* eslint-disable */

import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Link,
  Skeleton,
} from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { INIT_PROVIDER } from "../../../../graphql/mutations/provider";
import ProgressiveImage from "react-progressive-image";
import useAuth from "../../../../hooks/useAuth";

export const getGameUrl = (name) => {
  if (name === "TitanC") return "/titanc";
  if (name === "Maze") return "/maze";
  if (name === "Squid Game Test") return "/squid-game";
  if (name === "Crazy Monkey") return "/crazy-monkey";

  return "";
};

const GameCard = (props) => {
  const { game, ...other } = props;
  const auth = useAuth();

  const navigate = useNavigate();

  const [initProvider, { loading }] = useMutation(INIT_PROVIDER);

  const handleClickPlay = async () => {
    if (!auth.isAuthenticated) {
      auth.openLoginDialog();

      return;
    }

    const gamePath = getGameUrl(game.name);

    if (gamePath) {
      navigate(`${gamePath}`);

      return;
    }

    if (window.innerWidth <= 768) {
      const { data } = await initProvider({
        variables: {
          model: {
            providerId: +game.provider.id,
            gameId: +game.id,
          },
        },
      });

      if (data?.initProvider?.iframeUrl) {
        window.location.href = data.initProvider.iframeUrl
          .replace("https://lobby.mtgames.dev", "https://lobby.mtgames.live")
          .replace("dgafantasy-dev.com", "game.dgafantasy-dev.com");
      }
      return;
    }
    navigate(`/lobby/game/${game.id}/provider/${game.provider.id}`);
  };

  return (
    <Card {...other}>
      <Box>
        <ProgressiveImage
          src={game.imagePath || "/static/games/maze.png"}
          placeholder="/static/games/baccarat-game.jpg"
        >
          {(src, isLoading) =>
            isLoading ? (
              <Skeleton
                sx={{
                  borderRadius: 1,
                  width: "100%",
                  height: 280,
                  backgroundColor: "background.paper",
                }}
                variant="rectangular"
                animation="wave"
              />
            ) : (
              <CardMedia
                image={src}
                sx={{
                  backgroundColor: "background.default",
                  height: 280,
                }}
              />
            )
          }
        </ProgressiveImage>
      </Box>

      <Box
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          display: "flex",
          px: 2,
          py: 2,
        }}
      >
        <Link color="textPrimary" variant="h6">
          {game.name}
        </Link>
        <Button
          color="primary"
          variant="contained"
          endIcon={<KeyboardArrowRightIcon fontSize="small" />}
          onClick={handleClickPlay}
          disabled={loading}
        >
          Play game
        </Button>
      </Box>
    </Card>
  );
};

GameCard.propTypes = {
  game: PropTypes.object.isRequired,
};

export default GameCard;
