import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Box, Button, ListItem } from '@material-ui/core';
import { INIT_PROVIDER } from '../../../graphql/mutations/provider';
import { getGameUrl } from '../../../modules/home/components/games/GameCard';

const NavItem = (props) => {
  const { icon, path, title, gameProviderId, id, ...other } = props;

  const navigate = useNavigate();

  const [initProvider, { loading }] = useMutation(INIT_PROVIDER);

  const handleClickPlay = async () => {
    const gamePath = getGameUrl(title);

    if (gamePath) {
      navigate(`${gamePath}`);

      return;
    }

    if (window.innerWidth <= 768) {
      const { data } = await initProvider({
        variables: {
          model: {
            providerId: +gameProviderId,
            gameId: +id
          }
        }
      });

      if (data?.initProvider?.iframeUrl) {
        window.location.href = data.initProvider.iframeUrl;
      }
      return;
    }
    navigate(`/lobby/game/${id}/provider/${gameProviderId}`);
  };

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0
      }}
      {...other}
    >
      <Button
        onClick={handleClickPlay}
        endIcon={icon}
        sx={{
          color: 'text.secondary',
          fontWeight: 'fontWeightMedium',
          justifyContent: 'flex-start',
          textAlign: 'left',
          pr: '8px',
          py: '12px',
          textTransform: 'none',
          width: '100%',
        }}
        variant="text"
        disabled={loading}
      >
        <Box sx={{ flexGrow: 1 }}>
          {title}
        </Box>
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  gameProviderId: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  active: false,
  open: false
};

export default NavItem;
